import { Component, OnInit } from '@angular/core';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [NgxSpinnerModule],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss',
})
export class LoadingComponent implements OnInit {
  constructor(private spinner: NgxSpinnerService,private loaderService:LoadingService) {}
  ngOnInit(): void {
    this.loaderService.isLoading.subscribe((data)=>{
      if(data)
        this.spinner.show()
      else 
      this.spinner.hide()
    })
  }
}
