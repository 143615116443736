import { CommonModule, NgFor } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  FormGroup,
  FormControl,
  Validators,
  NonNullableFormBuilder,
} from '@angular/forms';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import {
  RoleAttributes,
  RoleData,
  SingleData,
  Attributes,
} from '../../models/data';
import { RolesService } from '../../services/roles.service';
import { KeycloakAdminService } from '../../services/keycloak-admin.service';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [
    NzTableModule,
    NzDividerModule,
    NgFor,
    NzAffixModule,
    NzButtonModule,
    NzIconModule,
    NzFlexModule,
    NzDrawerModule,
    NzFormModule,
    FormsModule,
    NzInputModule,
    NzSwitchModule,
    NzSelectModule,
    NzMessageModule,
    NzPopconfirmModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.css',
})
export class RolesComponent implements OnInit {
  isUpdate: boolean = false;
  openedRoleId: string = '';
  searchText: string = '';
  allRoles: any;
  createNewRole() {
    this.isUpdate = false;
    this.newRoleForm.reset();
    this.open();
  }
  editRole(data: any) {
    this.newRoleForm.reset();
    this.isUpdate = true;
    this.open();
    this.openedRoleId = data.id;
    try {
      this.newRoleForm.setValue(data.attributes);
    } catch (e) {}
    // this.newRoleForm.controls.slug.setValue(data.attributes.slug);
    this.newRoleForm.controls.name.setValue(data.name);
    this.newRoleForm.controls.description.setValue(data.description);
  }
  cancel() {}
  confirm(id: string) {
    this.kcAdminService
      .deleteReleamRoles(id)
      .then((data) => {
        this.loadRoles();
        this.createMessage(
          this.langService.lang == 'en'
            ? 'Role has been deleted'
            : 'Rolle wurde gelöscht',
          'success'
        );
      })
      .catch((err) => {
        this.loadRoles();
      });
    // this.roleService.deleteRole(id).subscribe({
    //   complete: () => {
    //     this.createMessage('Role has been deleted', 'success');
    //     this.loadRoles();
    //   },
    //   error: (err) => {
    //     this.createMessage(err.statusText, 'error');
    //   },
    // });
  }

  getRolesParams: HttpParams;

  handlePageChange($event: number) {
    this.getRolesParams = this.getRolesParams.set('page[number]', $event);
    this.loadRoles();
  }

  newRoleForm: FormGroup<{
    name: FormControl<string>;
    description: FormControl<string>;
  }> = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', [Validators.required]],
  });

  async createRole() {
    let roleAttributes: RoleAttributes = this.mapFormToRoleAttributes();
    if(!this.newRoleForm.valid) return
    const roleData: RoleData = {
      attributes: roleAttributes,
      type: 'roles',
      id: roleAttributes.slug,
    };
    const data: SingleData = {
      data: roleData,
    };
    if (this.isUpdate) {
      data.data.id = this.openedRoleId;
      await this.kcAdminService
        .updateReleamRoles(this.newRoleForm.value, this.openedRoleId)
        .then((data) => {
          this.loadRoles();
          this.close();
          this.newRoleForm.reset();
          this.createMessage(
            this.langService.lang == 'en'
              ? 'Role has been updated'
              : 'Die Rolle wurde aktualisiert',
            'success'
          );
        })
        .catch((err) => {
          this.loadRoles();
          this.close();
          this.newRoleForm.reset();
        });

      // this.roleService.updateRole(this.openedRoleId, data).subscribe({
      //   complete: () => {
      //     this.createMessage('Role has been updated', 'success');
      //     this.loadRoles();
      //   },
      //   error: (err) => {
      //     this.createMessage(err.statusText, 'error');
      //   },
      // });
    } else {
      await this.kcAdminService
        .addReleamRoles(this.newRoleForm.value)
        .then((data) => {
          this.loadRoles();
          this.close();
          this.newRoleForm.reset();
        })
        .catch((err) => {
          this.loadRoles();
          this.close();
          this.newRoleForm.reset();
          if (err?.response?.status == 409) {
            this.createMessage(
              this.langService.lang == 'en'
                ? `Eine Schwesterrolle namens ${this.newRoleForm.value.name} ist bereits vorhanden.`
                : `Sibling role named ${this.newRoleForm.value.name} already exists.`,
              'error'
            );
          }
        });
      // this.roleService.createRole(data).subscribe({
      //   complete: () => {
      //     this.createMessage("Role has been created", "success");
      //     this.loadRoles();
      //   }
      //   ,
      //   error: (err) => {
      //     this.createMessage(err.statusText, "error")
      //   },
      // })
    }

    this.newRoleForm.reset();
    this.close();
  }
  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }
  roleService = inject(RolesService);
  rolesData: any;
  visible = false;

  attributes = {} as Attributes;
  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }
  async ngOnInit() {
    this.getRolesParams = this.getRolesParams.append('page[number]', 1);
    this.getRolesParams = this.getRolesParams.append('page[size]', 5);
    await this.loadRoles();
  }
  constructor(
    private fb: NonNullableFormBuilder,
    private message: NzMessageService,
    private kcAdminService: KeycloakAdminService,
    private langService: LangService
  ) {
    this.getRolesParams = new HttpParams();
  }
  private async loadRoles() {
    await this.kcAdminService.getReleamRoles().then((data) => {
      this.rolesData = data;
      this.allRoles = data;
    });

    // this.roleService.getRoles(params).subscribe(res => {
    //   this.rolesData = res;

    // });
  }
  private getFilterParams(status: string): HttpParams {
    return this.getRolesParams;
  }

  mapFormToRoleAttributes(): RoleAttributes {
    return {
      slug: this.newRoleForm.get('slug')?.value,
      name: {
        de: this.newRoleForm.get('name')?.value,
        en: this.newRoleForm.get('name')?.value,
      },
      description: {
        de: this.newRoleForm.get('description')?.value,
        en: this.newRoleForm.get('description')?.value,
      },
    };
  }

  searchResult() {
    if (this.searchText?.length) {
      this.rolesData = this.allRoles.filter((app: any) => {
        return app.name?.toLowerCase().includes(this.searchText.toLowerCase());
      });
    } else {
      this.rolesData = this.allRoles;
    }
  }
  clearSearch() {
    this.searchText = '';
    this.rolesData = this.allRoles;
  }

  highlightText(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );
  }
}
