import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, Router, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import { provideNzIcons } from './icons-provider';
import { de_DE, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import de from '@angular/common/locales/de';
import { FormsModule } from '@angular/forms';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAuth } from 'angular-auth-oidc-client';
import { authConfig } from './auth/auth.config';
import { loadingInterceptor } from './interceptors/loading.interceptor';
import { httpInterceptor } from './interceptors/http.interceptor';
import { TranslateCompiler, TranslateDefaultParser, TranslateFakeCompiler, TranslateLoader, TranslateModule, TranslateParser, TranslateService, TranslateStore } from '@ngx-translate/core';
import { CustomTranslateLoader } from './translate-loader';

registerLocaleData(de);
export function createTranslateLoader(http: HttpClient, router: Router) {
  return new CustomTranslateLoader(http);
}
export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideNzIcons(),
    provideNzI18n(de_DE),
    importProvidersFrom(FormsModule),
    importProvidersFrom(HttpClientModule),
    provideAnimations(),
    provideHttpClient(withInterceptors([loadingInterceptor, httpInterceptor])),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient, Router],
        },
        defaultLanguage: 'en',
      })
    ),
    provideAuth(authConfig),
  ],
  //providers: [provideRouter(routes), provideNzIcons(), provideNzI18n(de_DE), importProvidersFrom(FormsModule), importProvidersFrom(HttpClientModule), provideAnimations()]
};
