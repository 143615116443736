import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SingleData, UserData } from '../models/data';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }

  headers = { 'content-type': 'application/vnd.api+json', 'accept': 'application/vnd.api+json' };

  // public getUsers(params: HttpParams): Observable<any> {
  //   return this.http.get<any>("https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/users", { params: params, headers: this.headers });
  // }
  public createUser(userData: SingleData): Observable<any> {
    return this.http.post<any>("https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/users", userData, { headers: this.headers });
  }
  public updateUser(id: string, userData: SingleData): Observable<any> {
    return this.http.patch<any>(`https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/users/${id}`, userData, { headers: this.headers });
  }
  public deleteUser(id: string): Observable<any> {
    return this.http.delete<any>(`https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/users/${id}`);
  }

  exportUsers(groupId:string){
   return this.http.get<any>(
     environment.userApis + `/groups/${groupId}/export-users`
   );
  }
}
