import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformImage',
  standalone: true,
})
export class TransformImagePipe implements PipeTransform {
  public transform(imageObject: any): unknown {
    if (!imageObject || !imageObject.imageData) {
      return ''; // Return empty string if no image data is provided
    }

    // Extract the base64 image data from the image object
    const imageData = imageObject.imageData;

    // If the image data is in data URL format, extract the base64 part
    const base64ImageData = imageData.startsWith('data:image')
      ? imageData.split(',')[1]
      : imageData;

    // Construct the URL for the image
    const imageUrl = `data:${imageObject.contentType};base64,${base64ImageData}`;

    return imageUrl;
  }
}
