import { Component, ElementRef, OnInit, inject } from '@angular/core';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { CommonModule, NgFor } from '@angular/common';
import { UsersService } from '../../services/users.service';
import {
  Attributes,
  SingleData,
  UserAttributes,
  UserData,
} from '../../models/data';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import {
  FormControl,
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { HttpParams } from '@angular/common/http';
import { KeycloakAdminService } from '../../services/keycloak-admin.service';
import UserRepresentation from '@keycloak/keycloak-admin-client/lib/defs/userRepresentation';
import { filterFormValues } from '../../models/form-utility';
import { RouterLink } from '@angular/router';
import { ClickPropagationDirective } from '../../directives/click-propagation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-users',
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    NzDividerModule,
    NgFor,
    NzAffixModule,
    NzButtonModule,
    NzIconModule,
    NzFlexModule,
    NzDrawerModule,
    NzFormModule,
    FormsModule,
    NzInputModule,
    NzSwitchModule,
    NzSelectModule,
    NzMessageModule,
    NzPopconfirmModule,
    ReactiveFormsModule,
    RouterLink,
    ClickPropagationDirective,
    TranslateModule,
  ],
  templateUrl: './users.component.html',
  styleUrl: './users.component.css',
})
export class UsersComponent implements OnInit {
  isUpdate: boolean = false;
  openedUserId: string = '';
  drawerTitle: string = 'New Form';
  kcAdminService = inject(KeycloakAdminService);
  langService = inject(LangService);
  searchText: any;
  allUsers: any;
  currentPage: number = 1;
  pageSize: number = 20;
  totalUsers!: number;
  usersPag: any;
  editableUser: any;
  createNewUser() {
    this.isUpdate = false;
    this.drawerTitle = 'New user';
    this.newUserForm.reset();
    this.newUserForm.controls['username'].enable();
    this.open();
  }
  editUser(data: any) {
    this.editableUser = data;
    this.drawerTitle = 'Edit user';
    this.newUserForm.reset();
    this.newUserForm.controls['username'].disable();
    this.isUpdate = true;
    this.open();
    this.openedUserId = data.id;
    try {
      const filtered = filterFormValues(this.newUserForm, data);
      filtered.password = 'dummy';
      if(!filtered.email) filtered.email = '';
      this.newUserForm.setValue(filtered);
      console.log(data);

      if (data.requiredActions.includes('CONFIGURE_TOTP'))
        this.newUserForm.get('totp')?.setValue(true);
    } catch (e) {
      console.error(e);
    }
  }
  cancel() {}
  async confirm(id: string) {
    await this.kcAdminService.deleteUser(id).then((res) => {
      this.createMessage(
        this.langService.lang == 'en'
          ? 'User has been deleted'
          : 'Benutzer wurde gelöscht',
        'success'
      );
    });
    this.users = await this.kcAdminService.getUsers();
  }

  getUsersParams: HttpParams;

  // handlePageChange($event: number) {
  //   this.getUsersParams = this.getUsersParams.set('page[number]', $event);
  //   // this.loadUsers(this.getUsersParams);
  // }

  newUserForm: FormGroup<{
    email: FormControl<string>;
    username: FormControl<string>;
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    enabled: FormControl<boolean>;
    password: FormControl<string>;
    totp: FormControl<boolean>;
  }> = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
    username: ['', [Validators.required]],
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    password: ['', [Validators.required]],
    enabled: [true],
    totp: [false, [Validators.required]],
  });

  statusFilters = [
    { text: 'Active', value: true },
    { text: 'Inactive', value: false },
    { text: 'All', value: 'all' },
  ];
  filterByStatus = (status: any) => {
    if (status == null) this.users = this.allUsers;
    else if (status != 'all') {
      this.users = this.allUsers.filter((user: any) => {
        return user.enabled == status;
      });
    } else {
      this.users = this.allUsers;
    }
    // this.loadUsers(this.getFilterParams(status));
  };
  async createUser() {
    let user: any = this.mapFormToUserAttributes();
    if (!this.newUserForm.valid) return;

    /* const userData: UserData = {
      attributes: userAttributes,
      type: "users"
    };
    const data: SingleData = {
      data: userData
    } */
    if (this.isUpdate) {
      /* data.data.id = this.openedUserId;
      this.userService.updateUser(this.openedUserId, data).subscribe({
        complete: () => {
          this.createMessage("User has been updated", "success");
          this.loadUsers(this.getFilterParams("all"));
        }
        ,
        error: (err) => {
          this.createMessage(err.statusText, "error")
        },
      }) */
      if (user.totp == true) {
        console.log('yes');

        user.requiredActions = ['CONFIGURE_TOTP'];
      } else {
        user.requiredActions = this.editableUser.requiredActions.filter(
          (item: string) => {
            return item != 'CONFIGURE_TOTP';
          }
        );
      }
      await this.kcAdminService
        .updateUser(this.openedUserId, user)
        .then((res) => {
          this.createMessage(
            this.langService.lang == 'en'
              ? 'User has been updated'
              : 'Der Benutzer wurde aktualisiert',
            'success'
          );
          this.loadUsers();
        });
    } else {
      if (!this.newUserForm.valid) return;
      /* this.userService.createUser(user).subscribe({
        complete: () => {
          this.createMessage("User has been created", "success");
          this.loadUsers(this.getFilterParams("all"));
        }
        ,
        error: (err) => {
          this.createMessage(err.statusText, "error")
        },
      // }) */
      user.credentials = [
        { value: this.newUserForm.get('password')?.value, temporary: true },
      ];
      user.requiredActions = ['UPDATE_PASSWORD'];
      if (this.newUserForm.get('totp')?.value == true) {
        console.log('yes');

        user.requiredActions = ['CONFIGURE_TOTP'];
      }
      await this.kcAdminService
        .createUser(user)
        .then((res) => {
          this.createMessage(
            this.langService.lang == 'en'
              ? 'User has been created'
              : 'Benutzer wurde erstellt',
            'success'
          );
          this.loadUsers();
        })
        .catch((error) => {
          if (error?.response?.status == 409) {
            this.createMessage(
              this.langService.lang == 'en'
                ? 'User exists with same username'
                : 'Benutzer existiert mit demselben Benutzernamen',
              'error'
            );
          }
        });
    }
    this.users = await this.kcAdminService.getUsers();
    this.newUserForm.reset();
    this.close();
  }
  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }
  userService = inject(UsersService);
  usersData: any = {};
  visible = false;

  attributes = {} as Attributes;
  open(): void {
    this.visible = true;
  }

  close(): void {
    this.visible = false;
  }
  users: any[] = [];
  async ngOnInit() {
    /* this.getUsersParams = this.getFilterParams("all");
    this.getUsersParams = this.getUsersParams.append("page[number]", 1);
    this.getUsersParams = this.getUsersParams.append("page[size]", 5);
    this.getUsersParams = this.getUsersParams.append("include", "acls.role");
    this.loadUsers(this.getUsersParams); */
    this.loadUsers();
    this.loadTotalCount();
    // await this.getAllUsers();
  }
  constructor(
    private fb: NonNullableFormBuilder,
    private message: NzMessageService
  ) {
    this.getUsersParams = new HttpParams();
  }
  async getAllUsers() {
    this.users = await this.kcAdminService.getUsers();
    this.allUsers = this.users;
  }
  // private loadUsers(params: HttpParams) {
  //   this.userService.getUsers(params).subscribe((res) => {
  //     this.users = res;

  //   });
  // }
  private getFilterParams(status: string): HttpParams {
    this.getUsersParams = this.getUsersParams.append(
      'filter[isActive]',
      status
    );
    return this.getUsersParams;
  }

  mapFormToUserAttributes(): any {
    return {
      username: this.newUserForm.get('username')?.value,
      firstName: this.newUserForm.get('firstName')?.value,
      lastName: this.newUserForm.get('lastName')?.value,
      email: this.newUserForm.get('email')?.value,
      enabled: this.newUserForm.get('enabled')?.value,
      totp: this.newUserForm.get('totp')?.value,
    };
  }

  searchResult() {
    this.kcAdminService.getSearchUsers(this.searchText).then((data) => {
      if (!this.searchText) this.users = this.allUsers;

      this.users = data;
    });
    // this.users = this.allUsers.filter((user: any) => {
    //   return (
    //     user.username
    //       ?.toLowerCase()
    //       .includes(this.searchText.toLowerCase()) ||
    //     user.firstName
    //       ?.toLowerCase()
    //       .includes(this.searchText.toLowerCase()) ||
    //     user.lastName
    //       ?.toLowerCase()
    //       .includes(this.searchText.toLowerCase()) ||
    //     user.email?.toLowerCase().includes(this.searchText.toLowerCase())
    //   );
    // });
  }
  clearSearch() {
    this.searchText = '';
    this.getAllUsers();
  }

  highlightText(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text?.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );
  }

  loadUsers() {
    const first = (this.currentPage - 1) * this.pageSize;
    this.kcAdminService.getUsersPaginated(first, this.pageSize).then(
      (data: any) => {
        this.users = data;
        this.allUsers = this.users;
      },
      (error) => {
        console.error('Error fetching users', error);
      }
    );
  }

  loadTotalCount() {
    this.kcAdminService.getUsersTotalCount().then(
      (data: any) => {
        this.totalUsers = data; // Update this line based on your data structure
        console.log(this.totalUsers);
      },
      (error) => {
        console.error('Error fetching users', error);
      }
    );
  }

  onPageChange(page: number): void {
    this.currentPage = page;
    this.loadUsers();
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.currentPage = 1; // Reset to first page
    this.loadUsers();
  }
}
