import { FormGroup } from '@angular/forms';
import {FormValue} from './form-value'
export function filterFormValues(form: FormGroup, values: any): any {
  const formValue: FormValue = {};
  Object.keys(form.controls).forEach(key => {
    if (values.hasOwnProperty(key)) {
      formValue[key] = values[key];
    }
  });
  return formValue;
}