<div>
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="ball-spin"
    [fullScreen]="true"
  >
    <div class="spinner"></div>

    </ngx-spinner
  >
</div>
