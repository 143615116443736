import { HttpParams } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { ScopesService } from '../../services/scopes.service';
import { NzGridModule } from 'ng-zorro-antd/grid';

@Component({
  selector: 'app-scopes',
  standalone: true,
  imports: [NzGridModule],
  templateUrl: './scopes.component.html',
  styleUrl: './scopes.component.css'
})
export class ScopesComponent implements OnInit{
  getScopesParams: HttpParams= new HttpParams();
  scopes: any ={};
  private scopesService = inject(ScopesService);

  ngOnInit(): void {
    /* this.getScopesParams = this.getFilterParams("all");
    this.getScopesParams = this.getScopesParams.append("page[number]", 1);
    this.getScopesParams = this.getScopesParams.append("page[size]", 5);*/
    this.getScopesParams = this.getScopesParams.append("sort", "full_name"); 
    this.loadScopes(this.getScopesParams);
  }
  private loadScopes(params: HttpParams) {
    this.scopesService.getScopes(params).subscribe(res => {
      this.scopes = res;

    });
  }
  private getFilterParams(status: string): HttpParams {
    this.getScopesParams = this.getScopesParams.append('filter[isActive]', status)
    return this.getScopesParams;
  }

}
