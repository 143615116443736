import { Routes } from '@angular/router';
import { ScopesComponent } from './pages/scopes/scopes.component';
import { AutoLoginPartialRoutesGuard } from 'angular-auth-oidc-client';
import { UsersComponent } from './pages/users/users.component';
import { RolesComponent } from './pages/roles/roles.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { EditUserComponent } from './pages/users/edit-user/edit-user.component';
import { EditGroupComponent } from './pages/groups/edit-group/edit-group.component';
import { ApplicationsComponent } from './pages/applications/applications.component';
import { EditUsersGroupComponent } from './pages/groups/edit-users-group/edit-users-group.component';
import { CategoriesComponent } from './pages/categories/categories.component';

export const routes: Routes = [
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'scopes',
    component: ScopesComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'groups',
    component: GroupsComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'groups/:id',
    component: EditGroupComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'groups/users/:id',
    component: EditUsersGroupComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'users/:id',
    component: EditUserComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'applications',
    component: ApplicationsComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'categories',
    component: CategoriesComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
  {
    path: 'roles',
    component: RolesComponent,
    canActivate: [AutoLoginPartialRoutesGuard],
  },
];
