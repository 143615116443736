import { Injectable, inject } from '@angular/core';
import { SingleData } from '../models/data';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private http = inject(HttpClient);
  headers = { 'content-type': 'application/vnd.api+json', 'accept': 'application/vnd.api+json' };

  public getRoles(params?: HttpParams): Observable<any> {
    return this.http.get<any>("https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/roles", { params: params, headers: this.headers });
  }
  public createRole(roleData: SingleData): Observable<any> {
    return this.http.post<any>("https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/roles", roleData, { headers: this.headers });
  }
  public updateRole(id: string, roleData: SingleData): Observable<any> {
    return this.http.patch<any>(`https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/roles/${id}`, roleData, { headers: this.headers });
  }
  public deleteRole(id: string): Observable<any> {
    return this.http.delete<any>(`https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/roles/${id}`);
  }
}
