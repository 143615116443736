<section class="p-3">
  <div class="d-flex justify-content-between mb-3">
    <div>
      <button
        nz-button
        nzSize="large"
        nzType="default"
        (click)="createCategory()"
        class="align-items-center d-flex addButton h-100"
      >
        {{ 'categories.addCategory' | translate }}
        <span nz-icon nzType="group" nzTheme="outline"></span>
      </button>
    </div>
    <div>
      <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch>
          <span nz-icon nzType="search"></span>
        </button>
      </ng-template>
      <nz-input-group
        nzSearch
        [nzAddOnAfter]="suffixIconButton"
        [nzSuffix]="suffixIcon"
      >
        <input
          type="text"
          nz-input
          placeholder="{{ 'categories.searchPlaceholder' | translate }}"
          [(ngModel)]="searchValue"
          (ngModelChange)="searchResult()"
        />
      </nz-input-group>
      <ng-template #suffixIcon>
        <i
          nz-icon
          nzType="close-circle"
          *ngIf="searchValue?.length"
          (click)="clearSearch()"
        ></i>
      </ng-template>
    </div>
  </div>
  <nz-table [nzScroll]="{ x: 'auto' }" #basicTable [nzData]="categories" [nzFrontPagination]="false">
    <thead>
      <tr>
        <th>{{ 'categories.tableHeaders.image' | translate }}</th>
        <th>{{ 'categories.tableHeaders.name' | translate }}</th>
        <th>{{ 'categories.tableHeaders.description' | translate }}</th>
        <th
          [nzFilters]="statusFilters"
          [nzFilterMultiple]="false"
          (nzFilterChange)="filterByStatus($event)"
        >
          {{ 'categories.tableHeaders.status' | translate }}
        </th>
        <th>{{ 'categories.tableHeaders.action' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data" style="cursor: pointer">
        <td>
          <a>
            <img
              [src]="data.imageBase64 | transformImage"
              [alt]="data.imageBase64.name"
              class="app-img"
              height="50px"
              [title]="data.imageBase64.name"
            />
          </a>
        </td>
        <td>
          <span [innerHTML]="highlightText(data.name, searchValue)"></span>
        </td>
        <td>{{ data.description }}</td>
        <td>
          <span
            [class.bg-active]="data.isActive"
            [class.bg-notactive]="!data.isActive"
            >{{ data.isActive ? 'Active' : 'Inactive' }}</span
          >
        </td>
        <td>
          <a
            appClickPropagation
            nz-icon
            nzType="edit"
            nzTheme="outline"
            (click)="editCategory(data)"
          ></a>
          <nz-divider nzType="vertical"></nz-divider>
          <a
            appClickPropagation
            nz-icon
            nzType="delete"
            nzTheme="outline"
            nz-popconfirm
            [nzPopconfirmTitle]="'categories.confirmDelete' | translate"
            [nzOkText]="'categories.ok' | translate"
            [nzCancelText]="'categories.cancel' | translate"
            class="text-danger"
            (nzOnConfirm)="confirm(data.id)"
          ></a>
        </td>
      </tr>
    </tbody>
  </nz-table>
</section>

<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="500"
  [nzVisible]="visible"
  nzTitle="{{ 'categories.drawerTitle' | translate }}"
  [nzFooter]="footerTpl"
  (nzOnClose)="close()"
>
  <form
    *nzDrawerContent
    nz-form
    [formGroup]="newGroupForm"
    (ngSubmit)="submit()"
  >
    <nz-form-item>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="isActive">
        {{ 'categories.isActive' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
      >
        <nz-switch id="isActive" formControlName="isActive"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="name">
        {{ 'categories.name' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'categories.nameError' | translate"
      >
        <input nz-input formControlName="name" id="name" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="description">
        {{ 'categories.description' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'categories.descriptionError' | translate"
      >
        <input nz-input formControlName="description" id="description" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="userGroups">
        {{ 'categories.assignRoles' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'categories.assignRolesError' | translate"
      >
        <nz-select
          [nzMaxTagCount]="5"
          nzMode="multiple"
          nzPlaceHolder="{{ 'categories.selectPlaceholder' | translate }}"
          [nzMaxTagPlaceholder]="tagPlaceHolder"
          formControlName="userGroups"
        >
          <nz-option
            *ngFor="let item of roles"
            [nzLabel]="item.name"
            [nzValue]="item.name"
          ></nz-option>
          <ng-template #tagPlaceHolder let-selectedList>
            {{ 'categories.tagPlaceholder' | translate: { count: selectedList.length } }}
          </ng-template>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired nzFor="upload">
        {{ 'categories.upload' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'categories.uploadError' | translate"
      >
        <div class="clearfix">
          <nz-upload
            class="upload-list-inline"
            nzAction="https://jsonplaceholder.typicode.com/posts/"
            nzListType="picture"
            [nzFileList]="fileList"
            (nzChange)="handleChange($event)"
            [nzPreview]="handleDownload"
          >
            <button nz-button type="button">
              <span nz-icon nzType="upload"></span>
              {{ 'categories.upload' | translate }}
            </button>
          </nz-upload>
        </div>
      </nz-form-control>
    </nz-form-item>
  </form>

  <ng-template #footerTpl>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="close()">
        {{ 'categories.cancelText' | translate }}
      </button>
      <button nz-button nzType="primary" (click)="submit()">
        {{ 'categories.submit' | translate }}
      </button>
    </div>
  </ng-template>
</nz-drawer>

