import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NzMessageService } from 'ng-zorro-antd/message';
import { KeycloakAdminService } from '../../../services/keycloak-admin.service';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../../services/lang.service';

@Component({
  selector: 'app-edit-group',
  standalone: true,
  imports: [
    TranslateModule,
    NzTransferModule,
    NzTableModule,
    NzSwitchModule,
    CommonModule,
  ],
  templateUrl: './edit-group.component.html',
  styleUrl: './edit-group.component.css',
})
export class EditGroupComponent implements OnInit {
  @Input() id: any;
  realmRoles: any;
  groupRoles: any;
  combinedRoles: any[] = [];
  targetKeys: any[] = [];
  disabled!: boolean;
  group: any;

  constructor(
    private kcAdminService: KeycloakAdminService,
    private message: NzMessageService,
    private langService: LangService
  ) {}

  async ngOnInit() {
    // console.log(this.id);
    if (this.id) {
      await this.getGroupData(this.id);
      await this.getRealmRoles();
      await this.getGrpupRoles(this.id);
      this.handelTrasfareDirections();
    }
  }

  //get group data
  async getGroupData(id: string) {
    this.kcAdminService.getGroupInfo(id).then((data) => {
      this.group = data;
      // console.log(this.group);
    });
  }

  //get all realm role
  async getRealmRoles() {
    await this.kcAdminService.getReleamRoles().then((data) => {
      this.realmRoles = data;
    });
  }

  //get group's roles
  async getGrpupRoles(id: string) {
    await this.kcAdminService.getRolesInGroup(id).then((data) => {
      this.groupRoles = data?.realmMappings;
      // console.log(this.groupRoles, data);
    });
  }

  //handel trasnfare data and directions for rolles
  handelTrasfareDirections() {
    const groupRoleIds = new Set(this.groupRoles?.map((role: any) => role.id));

    const filteredRealmRoles = this.realmRoles.filter(
      (role: any) => !groupRoleIds.has(role.id)
    );
    // console.log(this.groupRoles);

    // Combine groupRoles and filteredRealmRoles
    if (this.groupRoles?.length > 0) {
      this.combinedRoles = [
        ...this.groupRoles?.map((role: any) => ({
          id: role.id,
          name: role.name,
          direction: 'right',
        })),
        ...filteredRealmRoles.map((role: any) => ({
          id: role.id,
          name: role.name,
          direction: 'left',
        })),
      ];
    } else {
      this.combinedRoles = [
        ...filteredRealmRoles.map((role: any) => ({
          id: role.id,
          name: role.name,
          direction: 'left',
        })),
      ];
    }

    this.targetKeys = this.combinedRoles
      .filter((role: any) => role.direction === 'right')
      .map((role: any) => role.key);
  }

  //on transfare data
  onChangeRole(event: any): void {
    if (event?.list?.length == 0) return;
    this.disabled = true;
    if (event.from == 'right') {
      //delete role
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          id: data.id,
          name: data.name,
        });
      });
      this.kcAdminService.deleteGroupRole(this.id, newArray).then((data) => {
        this.createMessage(
          this.langService.lang == 'en'
            ? 'The roles have been successfully removed from the group'
            : 'Die Rollen wurden erfolgreich aus der Gruppe entfernt',
          'success'
        );
        this.combinedRoles.forEach((data: any, index: number) => {
          this.combinedRoles[index].disapled = false;
          // this.combinedGroups[index].checked = true;
        });
      });
    } else {
      //add role
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          id: data.id,
          name: data.name,
        });
      });
      // console.log(newArray, this.id);

      this.kcAdminService.addGroupRole(this.id, newArray).then((data) => {
        this.createMessage(
          this.langService.lang == 'en'
            ? 'The roles have been successfully added to the group'
            : 'Die Rollen wurden erfolgreich zur Gruppe hinzugefügt',
          'success'
        );
        this.combinedRoles.forEach((data: any, index: number) => {
          this.combinedRoles[index].disapled = false;
          // this.combinedGroups[index].checked = true;
        });
      });
    }
  }
  //create message
  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }
}
