import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import KeycloakAdminClient from '@keycloak/keycloak-admin-client';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KeycloakAdminService {
  private keycloakAdmin: KeycloakAdminClient;

  constructor() {
    this.keycloakAdmin = new KeycloakAdminClient({
      baseUrl:environment.authority,
      realmName: 'master',
    });
  }

  async authenticate() {
    await this.keycloakAdmin.auth({
      username: 'admin',
      password: 'c23x&U6ML#Xk3ZBw',
      grantType: 'password',
      clientId: 'admin-cli',
    });
  }

  async createUser(user: any) {
    await this.authenticate();
    await this.keycloakAdmin.users.create({
      realm: 'master',
      ...user,
    });
  }

  async getUsers() {
    await this.authenticate();
    return await this.keycloakAdmin.users.find({ realm: 'master' });
  }
  async getUsersPaginated(first: number, max: number) {
    await this.authenticate();

    return await this.keycloakAdmin.users.find({
      first: first,
      max: max,
    });
  }
  async getUsersTotalCount() {
    await this.authenticate();

    return await this.keycloakAdmin.users.count();
  }
  async getGroupsTotalCount() {
    await this.authenticate();

    return await this.keycloakAdmin.groups.count();
  }
  // async getRolesTotalCount() {
  //   await this.authenticate();

  //   return await this.keycloakAdmin.;
  // }
  async getGroups() {
    await this.authenticate();
    return await this.keycloakAdmin.groups.find();
  }
  async getGroupsPagination(first: number, max: number) {
    await this.authenticate();
    return await this.keycloakAdmin.groups.find({ first: first, max: max });
  }
  async addGroups(group: any) {
    await this.authenticate();
    return await this.keycloakAdmin.groups.create({
      realm: 'master',
      ...group,
    });
  }

  async addSubGroup(groupId: string, group: any) {
    await this.authenticate();
    return await this.keycloakAdmin.groups.createChildGroup(
      {
        id: groupId,
        realm: 'master',
      },
      group
    );
  }

  async deleteGroup(groupId: string) {
    return await this.keycloakAdmin.groups.del({
      id: groupId,
      realm: 'master',
    });
  }
  async updateGroup(groupId: string, group: any) {
    return await this.keycloakAdmin.groups.update(
      {
        id: groupId,
        realm: 'master',
      },
      group
    );
  }
  async getUsersInGroup(groupId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.groups.listMembers({
      realm: 'master',
      id: groupId,
    });
  }
  async getGroupsForUser(userId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.users.listGroups({
      realm: 'master',
      id: userId,
    });
  }

  async addUserToGroup(userId: string, groupId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.users.addToGroup({
      realm: 'master',
      id: userId,
      groupId: groupId,
    });
  }
  async getUserByUserName(username: string) {
    await this.authenticate();
    return await this.keycloakAdmin.users.find({ username });
  }
  async getSearchUsers(username: string) {
    await this.authenticate();
    return await this.keycloakAdmin.users.find({search: username });
  }
  async getgroupByName(name: string) {
    await this.authenticate();
    return await this.keycloakAdmin.groups.find({ search: name });
  }
  async deleteUserToGroup(userId: string, groupId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.users.delFromGroup({
      realm: 'master',
      id: userId,
      groupId: groupId,
    });
  }

  async getRolesInGroup(groupId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.groups.listRoleMappings({
      realm: 'master',
      id: groupId,
    });
  }
  async deleteUser(userId: string) {
    await this.authenticate();
    await this.keycloakAdmin.users.del({
      id: userId,
      realm: 'master',
    });
  }
  async updateUser(userId: string, user: any) {
    await this.authenticate();
    await this.keycloakAdmin.users.update(
      {
        id: userId,
        realm: 'master',
      },
      user
    );
  }
  async deleteUserFromGroup(userId: string, groupId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.users.delFromGroup({
      realm: 'master',
      id: userId,
      groupId: groupId,
    });
  }
  async getUserRoleMappings(userId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.users.listRoleMappings({
      realm: 'master',
      id: userId,
    });
  }
  async getUserInfo(userId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.users.findOne({
      id: userId,
      realm: 'master',
    });
  }
  async getUserRole(userId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.users.listRoleMappings({
      id: userId,
      realm: 'master',
    });
  }
  async addUserRoles(userId: string, roles: any) {
    await this.authenticate();
    return await this.keycloakAdmin.users.addRealmRoleMappings({
      id: userId,
      realm: 'master',
      roles: roles,
    });
  }
  async deleteUserRoles(userId: string, roles: any) {
    await this.authenticate();
    return await this.keycloakAdmin.users.delRealmRoleMappings({
      id: userId,
      realm: 'master',
      roles: roles,
    });
  }
  async getReleamRoles() {
    await this.authenticate();
    return await this.keycloakAdmin.roles.find();
  }

  async addReleamRoles(role: any) {
    await this.authenticate();
    return await this.keycloakAdmin.roles.create({
      realm: 'master',
      ...role,
    });
  }
  async updateReleamRoles(role: any, roleId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.roles.updateById(
      {
        realm: 'master',
        id: roleId,
      },
      { ...role }
    );
  }
  async deleteReleamRoles(roleId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.roles.delById({
      realm: 'master',
      id: roleId,
    });
  }
  async addGroupRole(groupId: string, roles: any) {
    await this.authenticate();
    return await this.keycloakAdmin.groups.addRealmRoleMappings({
      id: groupId,
      realm: 'master',
      roles: roles,
    });
  }
  async getGroupInfo(groupId: string) {
    await this.authenticate();
    return await this.keycloakAdmin.groups.findOne({
      id: groupId,
      realm: 'master',
    });
  }
  async deleteGroupRole(groupId: string, roles: any) {
    await this.authenticate();
    return await this.keycloakAdmin.groups.delRealmRoleMappings({
      id: groupId,
      realm: 'master',
      roles: roles,
    });
  }
  async updateUserPassword(userId: string, newPassword: string) {
    await this.authenticate();
    return await this.keycloakAdmin.users.update(
      { id: userId, realm: 'master' },
      { credentials: [{ type: 'password', value: newPassword }] }
    );
  }
}
