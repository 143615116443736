import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { SingleData } from '../models/data';

@Injectable({
  providedIn: 'root'
})
export class ScopesService {
  private http = inject(HttpClient);
  headers = { 'content-type': 'application/vnd.api+json', 'accept': 'application/vnd.api+json' };

  public getScopes(params: HttpParams): Observable<any> {
    return this.http.get<any>("https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/scopes", { params: params, headers: this.headers });
  }
  public createScope(ScopeData: SingleData): Observable<any> {
    return this.http.post<any>("https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/scopes", ScopeData, { headers: this.headers });
  }
  public updateScope(id: string, ScopeData: SingleData): Observable<any> {
    return this.http.patch<any>(`https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/Scopes/${id}`, ScopeData, { headers: this.headers });
  }
  public deleteScope(id: string): Observable<any> {
    return this.http.delete<any>(`https://api.emeis.dev.kommunity.projects.kogo4ic.de/api/v1/Scopes/${id}`);
  }
}
