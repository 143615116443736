
export const environment = {
  apiUrl: 'https://api.portal.dev.kommunity.projects.kogo4ic.de/v1',
  userApis:
    'https://user-management.dev.kommunity.projects.kogo4ic.de/v1/private',
  kommUnity:
    'https://komm-portal.dev.kommunity.projects.kogo4ic.de/portal/home',
  authority:
    'https://keycloak.dev.kommunity.projects.kogo4ic.de/auth',
};
