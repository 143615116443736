import { Component, Input, OnInit } from '@angular/core';
import { KeycloakAdminService } from '../../../services/keycloak-admin.service';
import RoleRepresentation from '@keycloak/keycloak-admin-client/lib/defs/roleRepresentation';
import { CommonModule } from '@angular/common';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { FormGroup, FormsModule, NonNullableFormBuilder } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { LoadingService } from '../../../services/loading.service';
import { UsersService } from '../../../services/users.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-users-group',
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    NzTableModule,
    NzIconModule,
    NzPopconfirmModule,
    NzSelectModule,
    FormsModule,
    NzButtonModule,
    NzInputModule,
  ],
  templateUrl: './edit-users-group.component.html',
  styleUrl: './edit-users-group.component.css',
})
export class EditUsersGroupComponent implements OnInit {
  @Input() id: any;
  groupUsers: any[] = [];
  orGroupUsers: any[] = [];
  usersList: any[] = [];
  roles: RoleRepresentation[] | undefined;
  allUsers: any;
  group: any;
  listOfSelectedUser: any;
  searchText: string = '';
  exportData: any;
  searchUsername!: string;
  firstUsersArray!: any[];
  constructor(
    private kcAdminService: KeycloakAdminService,
    private fb: NonNullableFormBuilder,
    private loadService: LoadingService,
    private userService: UsersService
  ) {}
  async ngOnInit() {
    await this.getUsers();
    if (this.id) {
      this.loadService.show();
      this.getExportedData(this.id);
      await this.getGroupDetails(this.id);
      await this.loadUsersAndRoles(this.id);
    }
  }
  getExportedData(id: any) {
    this.userService.exportUsers(id).subscribe({
      next: (data) => {
        this.exportData = data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
  //create form
  addUserForm: FormGroup = this.fb.group({});

  //get groupData by id
  async getGroupDetails(id: any) {
    this.kcAdminService.getGroupInfo(id).then((group) => {
      this.group = group;
    });
  }

  //load users according to group id then load list users
  async loadUsersAndRoles(groupId: string) {
    this.loadService.show();
    await this.kcAdminService.getUsersInGroup(groupId).then((users) => {
      this.groupUsers = users;
      this.orGroupUsers = users;
      let threeUserIds = new Set(this.groupUsers.map((user) => user.id));

      this.usersList = this.allUsers.filter(
        (user: any) => !threeUserIds.has(user.id)
      );
      this.firstUsersArray = this.usersList
      this.groupUsers.forEach((user) => {
        this.kcAdminService.getUserRoleMappings(user.id).then((roles) => {
          user.roles = roles;

          // console.log(user);
          this.loadService.hide();
        });
      });
    });
  }

  //load all users
  async getUsers() {
    await this.kcAdminService.getUsers().then((data) => {
      this.allUsers = data;
    });
  }

  //delete selected user from group
  async deleteUser(userId: string) {
    if (this.id) {
      await this.kcAdminService
        .deleteUserFromGroup(userId, this.id)
        .then(() => {
          this.loadUsersAndRoles(this.id);
        });
    }
  }

  //add new user for the group
  async addUserToGroup() {
    const promises = this.listOfSelectedUser.map((id: any) => {
      this.loadService.show();
      return this.kcAdminService
        .addUserToGroup(id, this.id)
        .then((data) => {})
        .catch((error) => {});
    });

    try {
      await Promise.all(promises);
    } catch (error) {
      console.error('Error adding users to group:', error);
    } finally {
      this.listOfSelectedUser = [];
      await this.loadUsersAndRoles(this.id);
      this.loadService.hide();
    }
  }

  //filterations according to search result
  searchResult() {
    if (this.searchText?.length) {
      this.groupUsers = this.orGroupUsers.filter((app: any) => {
        return app.username
          ?.toLowerCase()
          .includes(this.searchText.toLowerCase());
      });
    } else {
      this.groupUsers = this.orGroupUsers;
    }
  }
  clearSearch() {
    this.searchText = '';
    this.groupUsers = this.orGroupUsers;
  }
  highlightText(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );
  }

  downloadResponse() {
    const jsonData = JSON.stringify(this.exportData, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `${this.group?.name}_users.json`;
    a.click();
    URL.revokeObjectURL(url);
  }
  //get user by user name as search value
  getUserByUserName(username: string) {
    if (!username) return;
    this.kcAdminService.getUserByUserName(username).then((data) => {
      let threeUserIds = new Set(this.groupUsers.map((user) => user.id));

      this.usersList = data.filter(
        (user: any) => !threeUserIds.has(user.id)
      );
    });
  }

  onValueChange(event: any) {
   this.usersList = this.firstUsersArray;
  }
  // downloadExcel() {
  //   // Create a worksheet from the JSON data
  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.exportData);
  //   // Create a new workbook
  //   const workbook: XLSX.WorkBook = {
  //     Sheets: { data: worksheet },
  //     SheetNames: ['data'],
  //   };
  //   // Save to file
  //   XLSX.writeFile(workbook, 'response.xlsx');
  // }
}
