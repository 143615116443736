import { Component, OnInit, inject } from '@angular/core';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzTreeModule, NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzListModule } from 'ng-zorro-antd/list';
import { NgFor, NgIf } from '@angular/common';
import { KeycloakAdminService } from '../../services/keycloak-admin.service';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { DropdownModule } from 'primeng/dropdown';
import { RolesService } from '../../services/roles.service';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { filterFormValues } from '../../models/form-utility';
import { RouterLink } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { NzTableModule } from 'ng-zorro-antd/table';
import { TreeNode } from 'primeng/api';
import { TreeNodeInterface } from '../../models/treeNode';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-groups',
  standalone: true,
  imports: [
    NzTreeModule,
    NzGridModule,
    NzAffixModule,
    NzCardModule,
    NzListModule,
    NgIf,
    NgFor,
    NzIconModule,
    NzDrawerModule,
    NzButtonModule,
    ReactiveFormsModule,
    NzFormModule,
    NzPopconfirmModule,
    RouterLink,
    FormsModule,
    NzInputModule,
    NzSwitchModule,
    NzSelectModule,
    NzFlexModule,
    DropdownModule,
    NzTreeModule,
    NzTableModule,
    TranslateModule,
  ],
  templateUrl: './groups.component.html',
  styleUrl: './groups.component.css',
})
export class GroupsComponent implements OnInit {
  nodes: any[] = [];
  mapOfExpandedData: { [key: string]: TreeNodeInterface[] } = {};
  selectedNode: any;
  private kcAdminService = inject(KeycloakAdminService);
  users: any[] = [];
  roles: any[] | undefined = [];
  visible: boolean = false;
  newGroupRoles: any[] | undefined = [];
  groups: any[] = [];
  allgroupData: any;
  updatedSubGroup: any;
  visibleEdit: boolean = false;
  editableGroup: any;
  visibleAddUser: boolean = false;
  listOfSelectedUser: any;
  usersList: any;
  usersListForSelect: any;
  searchValue = '';
  searchValue$ = new BehaviorSubject<string>('');
  originGroups: any;
  filteredNodes!: any[];
  pageSize = 10;
  totalGroups!: number;
  currentPage: number = 1;
  constructor(
    private fb: NonNullableFormBuilder,
    private message: NzMessageService,
    private roleService: RolesService,
    private langService: LangService
  ) {}
  async ngOnInit() {
    await this.loadTotalCount();
    await this.loadGroups();
    await this.getUsers();
    await this.getRealmRoles();
  }
  onPageChange(page: number): void {
    this.currentPage = page;
    this.loadGroups();
  }
  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.currentPage = 1; // Reset to first page
    this.loadGroups();
  }
  cancel() {}

  filterNodes(): void {
    console.log(this.searchValue);
    if (this.searchValue.length > 0) {
      this.getGroupsByName(this.searchValue);
    } else {
      this.filteredNodes = this.nodes;
      this.filteredNodes.forEach((node) => {
        this.mapOfExpandedData[node.key] = this.convertTreeToList(node);
      });
    }
  }
  async getGroupsByName(name: string) {
    if (name?.length) {
      await this.kcAdminService.getgroupByName(name).then((data) => {
        // this.customizeGroups(data);
        this.filteredNodes = this.mapGroupsToTreeNodes(data);
      });
      this.filteredNodes.forEach((item: any) => {
        this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
      });
      this.filteredNodes.forEach((item) => {
        this.mapOfExpandedData[item.key] = this.convertTreeToList(item, true);
      });
    }
    if (this.searchValue.length == 0) {
      this.filteredNodes = this.nodes;
      this.filteredNodes.forEach((node) => {
        this.mapOfExpandedData[node.key] = this.convertTreeToList(node);
      });
    }
  }
  async loadTotalCount() {
    await this.kcAdminService.getGroupsTotalCount().then(
      (data: any) => {
        this.totalGroups = data; // Update this line based on your data structure
      },
      (error) => {
        console.error('Error fetching users', error);
      }
    );
  }
  filterTree(nodes: any[], searchValue: string): TreeNode[] {
    return nodes.filter((node) => {
      const matches = node.name.toLowerCase().includes(searchValue);
      if (node.children) {
        const filteredChildren = this.filterTree(node.children, searchValue);
        if (filteredChildren.length > 0 || matches) {
          node.children = filteredChildren;
          node.expand = true;
          return true;
        }
      }
      return matches;
    });
  }

  async getUsers() {
    await this.kcAdminService.getUsers().then((data) => {
      this.usersList = data;
    });
  }
  async confirm(groupId: any) {
    // console.log(groupId);
    await this.kcAdminService
      .deleteGroup(groupId)
      .then((data) => {
        // console.log(data);
      })
      .catch((error) => {
        // console.log(error);
      });
    this.loadGroups();
    this.selectedNode = null;
  }
  highlightText(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );
  }
  async loadGroups() {
    const first = (this.currentPage - 1) * this.pageSize;

    await this.kcAdminService
      .getGroupsPagination(first, this.pageSize)
      .then((groups) => {
        this.allgroupData = groups;
        this.originGroups = groups;
        this.groups = [];
        this.customizeGroups(groups);
        this.nodes = this.mapGroupsToTreeNodes(groups);
      });
    this.nodes.forEach((item: any) => {
      this.mapOfExpandedData[item.key] = this.convertTreeToList(item);
    });

    this.filteredNodes = this.nodes;
  }
  addUserForm: FormGroup = this.fb.group({});
  newGroupForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    path: ['', [Validators.required]],
    // access: [null, [Validators.required]],
    // subGroupCount: [0],
    // realmRoles: [null, Validators.required],
    // clientRoles: [null, Validators.required],
    // attributes: [null, Validators.required],
    // subGroups: this.fb.array([]),
  });
  editGroupForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    path: ['', [Validators.required]],
  });
  get subGroups(): FormArray {
    return this.newGroupForm.get('subGroups') as FormArray;
  }

  getFormArray(control: AbstractControl | null): FormArray {
    return control as FormArray;
  }

  createSubGroup(): FormGroup {
    return this.fb.group({
      name: ['', [Validators.required]],
      path: ['', [Validators.required]],
      access: [null, [Validators.required]],
      // subGroupCount: [0],
      realmRoles: [null, Validators.required],
      clientRoles: [null, Validators.required],
      attributes: [null, Validators.required],
      subGroups: this.fb.array([]),
    });
  }

  addSubGroup() {
    this.subGroups.push(this.createSubGroup());
  }

  addNestedSubGroup(subGroups: FormArray) {
    subGroups.push(this.createSubGroup());
  }

  removeSubGroup(subGroups: FormArray, index: number) {
    subGroups.removeAt(index);
  }
  async getRealmRoles() {
    await this.kcAdminService.getReleamRoles().then((data) => {
      this.newGroupRoles = data;
      // console.log(this.newGroupRoles);
    });
  }
  private loadRoles() {
    this.roleService.getRoles().subscribe((res) => {
      // console.log(res);
    });
  }
  mapGroupsToTreeNodes(groups: any[]): any[] {
    return groups.map((group) => ({
      name: group.name,
      key: group.id,
      expand: false,
      children: this.mapGroupsToTreeNodes(group.subGroups || []),
    }));
  }

  customizeGroups(groups: any[]) {
    groups.forEach((group) => {
      this.groups.push(group);
      if (group.subGroups.length > 0) this.customizeGroups(group.subGroups);
    });
    // console.log(this.groups);
  }
  addParent(subGroupEvent: any) {
    let subGroupId = subGroupEvent?.value;
    let subgroup = this.groups.filter((group) => {
      return group.id == subGroupId;
    });
    subgroup[0].subGroups.push(this.newGroupForm.value);
    this.updatedSubGroup = subgroup[0];
  }

  async selectNode(event: any) {
    this.selectedNode = event.node.origin;
    await this.loadUsersAndRoles(this.selectedNode.key);
  }

  async loadUsersAndRoles(groupId: string) {
    await this.kcAdminService.getUsersInGroup(groupId).then((users) => {
      this.users = users;
      let threeUserIds = new Set(this.users.map((user) => user.id));

      this.usersListForSelect = this.usersList.filter(
        (user: any) => !threeUserIds.has(user.id)
      );

      this.users.forEach((user) => {
        this.kcAdminService.getUserRoleMappings(user.id).then((roles) => {
          user.roles = roles;

          // console.log(user);
        });
      });
    });
    await this.kcAdminService.getRolesInGroup(groupId).then((roles) => {
      this.roles = roles.realmMappings;
    });
  }
  async deleteUser(userId: string) {
    if (this.selectedNode) {
      await this.kcAdminService
        .deleteUserFromGroup(userId, this.selectedNode.key)
        .then(() => {
          this.loadUsersAndRoles(this.selectedNode.key);
        });
    }
  }

  createCategory() {
    this.visible = true;
  }
  openEdit() {
    this.visibleEdit = true;
  }

  closeEdit() {
    this.visibleEdit = false;
  }
  setEditableGroup(group: any) {
    console.log(this.allgroupData, this.groups);

    let editableGroup = this.groups.filter((data: any) => {
      return data?.id == group.key;
    });
    console.log(editableGroup);
    this.openEdit();
    const filtered = filterFormValues(this.editGroupForm, editableGroup[0]);

    this.editGroupForm.setValue(filtered);
    this.editableGroup = editableGroup[0];
  }
  async editGroupData() {
    await this.kcAdminService
      .updateGroup(this.editableGroup.id, this.editGroupForm.value)
      .then((data) => {
        this.createMessage(
          this.langService.lang == 'en'
            ? 'Group has been updated'
            : 'Die Gruppe wurde aktualisiert',
          'success'
        );
      })
      .catch((error) => {});
    this.editGroupForm.reset();
    this.selectedNode = null;
    this.closeEdit();
    this.loadGroups();
  }
  async createNewCategory() {
    // console.log('create',this.newGroupForm.value, this.updatedSubGroup);
    if (!this.newGroupForm.valid) return;
    if (this.updatedSubGroup) {
      // console.log(this.updatedSubGroup);

      await this.kcAdminService
        .addSubGroup(this.updatedSubGroup?.id, this.newGroupForm.value)
        .then((data) => {
          // console.log(data);
        })
        .catch((error) => {
          if (error?.response?.status == 409) {
            this.createMessage(
              `Sibling group named ${this.newGroupForm.value.name} already exists.`,
              'error'
            );
          }
        });
    } else {
      await this.kcAdminService
        .addGroups(this.newGroupForm.value)
        .then((data) => {})
        .catch((error) => {});
    }
    this.newGroupForm.reset();
    this.close();
    this.loadGroups();
  }

  addUserToGroup() {}
  open(): void {
    this.visible = true;
  }

  close(): void {
    this.newGroupForm.reset();
    this.visible = false;
  }

  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }

  closeAddUser() {
    this.visibleAddUser = false;
  }

  clearSearch() {
    this.filteredNodes = this.nodes;
    this.searchValue = '';
    this.filteredNodes.forEach((node) => {
      this.mapOfExpandedData[node.key] = this.convertTreeToList(node);
    });
  }
  collapse(
    array: TreeNodeInterface[],
    data: TreeNodeInterface,
    $event: boolean
  ): void {
    if (!$event) {
      if (data.children) {
        data.children.forEach((d) => {
          const target = array.find((a) => a.key === d.key)!;
          target.expand = false;
          this.collapse(array, target, false);
        });
      } else {
        return;
      }
    }
  }

  convertTreeToList(
    root: TreeNodeInterface,
    expand: boolean = false
  ): TreeNodeInterface[] {
    const stack: TreeNodeInterface[] = [];
    const array: TreeNodeInterface[] = [];
    const hashMap = {};
    stack.push({ ...root, level: 0, expand: expand });

    while (stack.length !== 0) {
      const node = stack.pop()!;
      this.visitNode(node, hashMap, array);
      if (node.children) {
        for (let i = node.children.length - 1; i >= 0; i--) {
          stack.push({
            ...node.children[i],
            level: node.level! + 1,
            expand: expand,
            parent: node,
          });
        }
      }
    }

    return array;
  }

  visitNode(
    node: TreeNodeInterface,
    hashMap: { [key: string]: boolean },
    array: TreeNodeInterface[]
  ): void {
    if (!hashMap[node.key]) {
      hashMap[node.key] = true;
      array.push(node);
    }
  }
  async openUsers(item: any) {
    this.selectedNode = item;
    await this.loadUsersAndRoles(this.selectedNode.key);
    this.visibleAddUser = true;
  }
}
