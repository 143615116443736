import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  lang$ = new BehaviorSubject<string>('de');
  lang:string='de'
  constructor() {
    this.lang$.subscribe((data)=>{
      this.lang = data
    })
  }

  changeLanguage(lang: string) {
    this.lang$.next(lang);
  }
}
