import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICategory } from '../models/categories';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(private http: HttpClient) {}

  getAllCategories() {
    return this.http.get<ICategory[]>(environment.apiUrl + '/categories');
  }

  //Add category
  addCategory(app: any) {
    return this.http.post<any>(environment.apiUrl + `/categories`, {
      ...app,
    });
  }

  //edit category data
  editCategory(appId: any, app: any) {
    return this.http.patch<any>(environment.apiUrl + `/categories/${appId}`, {
      ...app,
    });
  }
  //delete Category
  deleteCategory(id: any) {
    return this.http.delete<any>(environment.apiUrl + `/categories/${id}`);
  }
}
