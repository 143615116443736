<section class="p-3">
  <div class="d-flex justify-content-between mb-3">
    <div>
      <button
        nz-button
        nzSize="large"
        nzType="default"
        (click)="createNewApp()"
        class="align-items-center d-flex addButton h-100"
      >
        {{ 'app.addNewApplication' | translate }}<span
          nz-icon
          nzType="appstore-add"
          nzTheme="outline"
        ></span>
      </button>
    </div>
    <div>
      <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch>
          <span nz-icon nzType="search"></span>
        </button>
      </ng-template>

      <nz-input-group
        nzSearch
        [nzAddOnAfter]="suffixIconButton"
        [nzSuffix]="suffixIcon"
      >
        <input
          type="text"
          nz-input
          [placeholder]="'app.searchPlaceholder' | translate"
          #searchValue
          [(ngModel)]="searchText"
          (input)="searchResult()"
        />
      </nz-input-group>
      <ng-template #suffixIcon>
        <i
          nz-icon
          nzType="close-circle"
          *ngIf="searchValue.value"
          (click)="clearSearch()"
        ></i>
      </ng-template>
    </div>
  </div>
  <nz-table #basicTable [nzData]="applications" [nzFrontPagination]="false" [nzScroll]="{ x: 'auto' }">
    <thead>
      <tr>
        <th>{{ 'app.table.image' | translate }}</th>
        <th>{{ 'app.table.name' | translate }}</th>
        <th>{{ 'app.table.description' | translate }}</th>
        <th>{{ 'app.table.link' | translate }}</th>
        <th
          [nzFilters]="statusFilters"
          [nzFilterMultiple]="false"
          (nzFilterChange)="filterByStatus($event)"
        >
          {{ 'app.table.status' | translate }}
        </th>
        <th nzWidth="100px">{{ 'app.table.action' | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data" style="cursor: pointer">
        <td>
          <a target="_blank" [href]="data?.link">
            <img
              [src]="data.imageBase64 | transformImage"
              [alt]="data.imageBase64.name"
              class="app-img"
              height="50px"
              [title]="data.imageBase64.name"
            />
          </a>
        </td>
        <td>
          <span [innerHTML]="highlightText(data.name, searchText)"></span>
        </td>
        <td>
          <span
            [innerHTML]="highlightText(data.description, searchText)"
          ></span>
        </td>
        <td>
          <span [innerHTML]="highlightText(data.link, searchText)"></span>
        </td>
        <td>
          <span
            [class.bg-active]="data.isActive"
            [class.bg-notactive]="!data.isActive"
          >
            {{ data.isActive ? ('app.table.active' | translate) : ('app.table.inactive' | translate) }}
          </span>
        </td>
        <td>
          <a
            appClickPropagation
            nz-icon
            nzType="edit"
            nzTheme="outline"
            (click)="editApp(data)"
          ></a>
          <nz-divider nzType="vertical"></nz-divider>
          <a
            appClickPropagation
            nz-icon
            nzType="delete"
            nzTheme="outline"
            nz-popconfirm
            [nzPopconfirmTitle]="'app.drawer.areYouSure' | translate"
            [nzOkText]="'app.drawer.ok' | translate"
            [nzCancelText]="'app.drawer.cancel' | translate"
            class="text-danger"
            (nzOnConfirm)="confirm(data.id)"
            (nzOnCancel)="cancel()"
          ></a>
        </td>
      </tr>
    </tbody>
  </nz-table>
</section>

<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="500"
  [nzVisible]="visible"
  [nzTitle]="'app.drawer.title' | translate"
  [nzFooter]="footerTpl"
  (nzOnClose)="close()"
>
  <form
    *nzDrawerContent
    nz-form
    [formGroup]="applicationForm"
    (ngSubmit)="submit()"
  >
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="isActive">
        {{ 'app.drawer.isActive' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
      >
        <nz-switch id="isActive" formControlName="isActive"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="isFavourite">
        {{ 'app.drawer.isFavourite' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
      >
        <nz-switch id="isFavourite" formControlName="isFavourite"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="name">
        {{ 'app.drawer.name' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'app.drawer.nameError' | translate"
      >
        <input nz-input formControlName="name" id="name" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="link">
        {{ 'app.drawer.link' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'app.drawer.linkError' | translate"
      >
        <input nz-input formControlName="link" id="link" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="description">
        {{ 'app.drawer.description' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'app.drawer.descriptionError' | translate"
      >
        <input nz-input formControlName="description" id="description" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="assignRoles">
        {{ 'app.drawer.assignRoles' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'app.drawer.assignRolesError' | translate"
      >
        <nz-select
          [nzMaxTagCount]="5"
          nzMode="multiple"
          [nzPlaceHolder]="'app.drawer.assignRolesPlaceholder' | translate"
          [nzMaxTagPlaceholder]="tagPlaceHolder"
          formControlName="roles"
        >
          <nz-option
            *ngFor="let item of roles"
            [nzLabel]="item.name"
            [nzValue]="item.name"
          ></nz-option>
          <ng-template #tagPlaceHolder let-selectedList>
            {{ 'app.drawer.tagPlaceholder' | translate: { count: selectedList.length } }}
          </ng-template>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="userGroups">
        {{ 'app.drawer.userGroups' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'app.drawer.userGroupsError' | translate"
      >
        <nz-tree-select
          *ngIf="transformedNodes?.length"
          style="width: 250px"
          [nzNodes]="transformedNodes"
          nzShowSearch
          [nzPlaceHolder]="'app.drawer.userGroupsPlaceholder' | translate"
          (ngModelChange)="onChange($event)"
          formControlName="userGroups"
          [nzMultiple]="true"
          [nzDefaultExpandAll]="true"
          [nzMaxTagCount]="3"
        ></nz-tree-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="categories">
        {{ 'app.drawer.categories' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'app.drawer.categoriesError' | translate"
      >
        <nz-select
          nzMode="default"
          [nzPlaceHolder]="'app.drawer.categoriesPlaceholder' | translate"
          formControlName="categoryId"
          id="categories"
        >
          <nz-option
            *ngFor="let item of categories"
            [nzLabel]="item.name"
            [nzValue]="item.id"
          ></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="image">
        {{ 'app.drawer.image' | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        [nzErrorTip]="'app.drawer.imageError' | translate"
      >
        <div class="clearfix">
          <nz-upload
            class="upload-list-inline"
            nzAction="https://jsonplaceholder.typicode.com/posts/"
            nzListType="picture"
            [nzFileList]="fileList"
            (nzChange)="handleChange($event)"
            [nzPreview]="handleDownload"
          >
            <button nz-button type="button">
              <span nz-icon nzType="upload"></span>
              {{ 'app.drawer.upload' | translate }}
            </button>
          </nz-upload>
        </div>
      </nz-form-control>
    </nz-form-item>
  </form>

  <ng-template #footerTpl>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="close()">
        {{ 'app.drawer.cancel' | translate }}
      </button>
      <button nz-button nzType="primary" (click)="submit()">
        {{ 'app.drawer.submit' | translate }}
      </button>
    </div>
  </ng-template>
</nz-drawer>

