import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appClickPropagation]',
  standalone: true
})
export class ClickPropagationDirective {

  @HostListener("click", ["$event"])
  public onClick(event: any): void {
    // console.log(event);
    event.stopPropagation();
  }

}
