 <section class="p-3">

   <h3 class="mb-3">
      {{"groups.rolesMange.mange"|translate}} {{ group?.name }} group
    </h3>
  
    <nz-transfer
      *ngIf="combinedRoles"
      [nzDataSource]="combinedRoles"
      [nzDisabled]="!combinedRoles.length"
      [nzTitles]="['Realm Roles', 'Group Roles']"
      [nzShowSelectAll]="false"
      [nzRenderList]="[renderList, renderList]"
      [nzTargetKeys]="targetKeys"
      (nzChange)="onChangeRole($event)"
      class="py-3"
    >
      <ng-template
        #renderList
        let-items
        let-direction="direction"
        let-stat="stat"
        let-disabled="disabled"
        let-onItemSelectAll="onItemSelectAll"
        let-onItemSelect="onItemSelect"
      >
        <nz-table [nzScroll]="{ x: 'auto' }" #t [nzData]="items" nzSize="small">
          <thead>
            <tr>
              <th
                [nzDisabled]="disabled"
                [nzChecked]="stat.checkAll"
                [nzIndeterminate]="stat.checkHalf"
                (nzCheckedChange)="onItemSelectAll($event)"
              ></th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of t.data" (click)="onItemSelect(data)">
              <td
                [nzChecked]="!!data.checked"
                [nzDisabled]="disabled || data.disabled"
                (nzCheckedChange)="onItemSelect(data)"
              ></td>
              <td>{{ data.name }}</td>
            </tr>
          </tbody>
        </nz-table>
      </ng-template>
    </nz-transfer>
 </section>
 