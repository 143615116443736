<section class="p-3">
  <div class="d-flex justify-content-between">
    <h3 class="mb-3">
      {{ "users.userDataFor" | translate }} {{ userData?.firstName }}
      {{ userData?.lastName }}
    </h3>
    <button (click)="visibleEdit = true" class="btn text-primary">
      {{ "users.changePassword" | translate }}
    </button>
  </div>

  <nz-transfer
    *ngIf="combinedRoles"
    [nzDataSource]="combinedRoles"
    [nzDisabled]="!combinedRoles?.length"
    [nzTitles]="['Realm Roles', 'User Roles']"
    [nzShowSelectAll]="false"
    [nzRenderList]="[renderList, renderList]"
    [nzTargetKeys]="targetKeys"
    (nzChange)="onChange($event)"
    class="py-3"
  >
    <ng-template
      #renderList
      let-items
      let-direction="direction"
      let-stat="stat"
      let-disabled="disabled"
      let-onItemSelectAll="onItemSelectAll"
      let-onItemSelect="onItemSelect"
    >
      <nz-table
        #t
        [nzData]="items"
        nzSize="small"
        [nzTotal]="roleTotal"
        [nzScroll]="{ x: 'auto' }"
      >
        <thead>
          <tr>
            <th
              [nzDisabled]="disabled"
              [nzChecked]="stat.checkAll"
              [nzIndeterminate]="stat.checkHalf"
              (nzCheckedChange)="onItemSelectAll($event)"
            ></th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of t.data" (click)="onItemSelect(data)">
            <td
              [nzChecked]="!!data.checked"
              [nzDisabled]="disabled || data.disabled"
              (nzCheckedChange)="onItemSelect(data)"
            ></td>
            <td>{{ data.name }}</td>
          </tr>
        </tbody>
      </nz-table>
    </ng-template>
  </nz-transfer>

  <nz-transfer
    *ngIf="combinedGroups"
    [nzDataSource]="combinedGroups"
    [nzDisabled]="!combinedGroups?.length || disapled"
    [nzTitles]="['Groups', 'User Groups']"
    [nzShowSelectAll]="false"
    [nzRenderList]="[renderList, renderList]"
    [nzTargetKeys]="targetKeysGroups"
    (nzChange)="onChangeGroup($event)"
    (nzSelectChange)="onItemSelect($event)"
    class="py-3"
  >
    <ng-template
      #renderList
      let-items
      let-direction="direction"
      let-stat="stat"
      let-disabled="disapled"
      let-onItemSelectAll="onItemSelectAll"
      let-onItemSelect="onItemSelect"
    >
      <nz-table
        #t
        [nzData]="items"
        [nzScroll]="{ x: 'auto' }"
        [nzData]="items"
        nzSize="small"
        [nzTotal]="groupTotal"
        [(nzPageIndex)]="groupCurrentPage"
        [nzPageSize]="groupPageSize"
        (nzPageIndexChange)="onPageGroupChange($event)"
        [nzFrontPagination]="false"
      >
        <thead>
          <tr>
            <th
              [nzDisabled]="disapled"
              [nzChecked]="stat.checkAll"
              [nzIndeterminate]="stat.checkHalf"
              (nzCheckedChange)="onItemSelectAll($event)"
            ></th>
            <th>Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of t.data" (click)="onItemSelect(data)">
            <td
              [nzChecked]="!!data.checked"
              [nzDisabled]="disapled || data.disapled"
              (nzCheckedChange)="onItemSelect(data)"
            ></td>
            <td>{{ data.name }}</td>
          </tr>
        </tbody>
      </nz-table>
    </ng-template>
  </nz-transfer>
</section>

<!-- =======================change password form ============================================= -->
<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="500"
  [nzVisible]="visibleEdit"
  nzTitle="Kennwort ändern"
  [nzFooter]="footerEdit"
  (nzOnClose)="close()"
>
  <form
    *nzDrawerContent
    nz-form
    [formGroup]="changePasswordForm"
    (ngSubmit)="changeUserPassword()"
  >
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="password">
        {{ "users.password" | translate }}
      </nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="'users.passwordRequired' | translate">
        <input
          nz-input
          formControlName="password"
          id="password"
          type="password"
          minlength="8"
        />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="confirmationPassword">
        {{ "users.confirmPassword" | translate }}
      </nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24" [nzErrorTip]="'users.confirmPasswordMismatch' | translate">
        <input
          nz-input
          type="password"
          formControlName="confirmationPassword"
          id="confirmationPassword"
          [pattern]="changePasswordForm.value.password"
        />
      </nz-form-control>
    </nz-form-item>
  </form>

  <ng-template #footerEdit>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="close()">
        {{ "users.cancel" | translate }}
      </button>
      <button
        type="button"
        nz-button
        nzType="primary"
        (click)="changeUserPassword()"
      >
        {{ "users.submit" | translate }}
      </button>
    </div>
  </ng-template>
</nz-drawer>
