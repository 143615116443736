import { NgFor, CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  FormsModule,
  FormGroup,
  Validators,
  NonNullableFormBuilder,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFlexModule } from 'ng-zorro-antd/flex';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTableModule } from 'ng-zorro-antd/table';
import { ICategory } from '../../models/categories';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzMessageModule, NzMessageService } from 'ng-zorro-antd/message';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { ClickPropagationDirective } from '../../directives/click-propagation.directive';
import { TransformImagePipe } from '../../pipes/transform-image.pipe';
import { CategoriesService } from '../../services/categories.service';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-categories',
  standalone: true,
  imports: [
    TransformImagePipe,
    CommonModule,
    NzTableModule,
    NzDividerModule,
    NgFor,
    NzAffixModule,
    NzButtonModule,
    NzIconModule,
    NzFlexModule,
    NzDrawerModule,
    NzFormModule,
    FormsModule,
    NzInputModule,
    NzSwitchModule,
    NzSelectModule,
    NzMessageModule,
    NzPopconfirmModule,
    ReactiveFormsModule,
    RouterLink,
    ClickPropagationDirective,
    NzUploadModule,
    NzModalModule,
    TranslateModule,
  ],
  templateUrl: './categories.component.html',
  styleUrl: './categories.component.css',
})
export class CategoriesComponent implements OnInit {
  searchValue: string = '';
  categories: ICategory[] = [];
  allCategories: ICategory[] = [];
  visible: boolean = false;
  isUpdate!: boolean;
  drawerTitle: string = '';
  statusFilters = [
    { text: 'Active', value: true },
    { text: 'Inactive', value: false },
    { text: 'All', value: 'all' },
  ];
  category!: ICategory;
  fileList: any[] = [];
  roles: any[] = [{ name: 'user' }, { name: 'admin' }, { name: 'Non' }];
  userGroups!: string[];

  constructor(
    private fb: NonNullableFormBuilder,
    private categoryService: CategoriesService,
    private message: NzMessageService,
    private langService: LangService
  ) {}
  ngOnInit(): void {
    this.getAllCategories();
  }

  getAllCategories() {
    this.categoryService.getAllCategories().subscribe({
      next: (data) => {
        this.categories = data;
        this.allCategories = data;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  //implement category form controls
  newGroupForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    description: ['', [Validators.required]],
    userGroups: [[], [Validators.required]],
    isActive: [false, [Validators.required]],
    imageBase64: this.fb.group({
      name: ['', [Validators.required]],
      contentType: ['', [Validators.required]],
      imageData: ['', [Validators.required]],
    }),
  });
  //open create drawer
  createCategory() {
    this.visible = true;
  }

  editCategory(category: ICategory) {
    this.visible = true;
    this.isUpdate = true;
    this.category = category;
    this.drawerTitle = 'Edit ' + category?.name + ' Category';
    this.userGroups = category.userGroups.map((role) => role.name);

    this.fileList = [
      {
        uid: '-1',
        name: category.imageBase64.name,
        status: 'done',
        url: category.imageBase64.imageData,
        thumbUrl: category.imageBase64.imageData,
      },
    ];

    this.newGroupForm.patchValue({
      name: category.name,
      description: category.description,
      isActive: category.isActive,
      userGroups: this.userGroups,
      imageBase64: {
        name: category.imageBase64.name,
        contentType: category.imageBase64.contentType,
        imageData: category.imageBase64.imageData,
      },
    });
  }
  //close drawer form
  close() {
    this.fileList = [];
    this.newGroupForm.patchValue({
      imageBase64: {
        name: '',
        contentType: '',
        imageData: '',
      },
    });
    this.newGroupForm.reset();
    this.visible = false;
  }

  //delete category
  confirm(id: number) {
    this.categoryService.deleteCategory(id).subscribe({
      complete: () => {
        this.getAllCategories();
        this.createMessage(
          this.langService.lang == 'en'
            ? 'category was deleted'
            : 'Kategorie wurde gelöscht',
          'success'
        );
      },
      error:(err)=> {
         this.createMessage(
           err.error.reason,'error'
         );
        
      },
    });
  }
  //create or edit category
  submit() {
    if (!this.newGroupForm.valid) return;
    this.newGroupForm.value.userGroups = this.newGroupForm.value.userGroups.map(
      (role: any) => ({
        name: role,
      })
    );
    if (this.isUpdate) {
      this.categoryService
        .editCategory(this.category.id, this.newGroupForm.value)
        .subscribe({
          next: (data) => {
            this.getAllCategories();
            this.createMessage(
              this.langService.lang == 'en'
                ? `Category ${data.name} added succefully`
                : `Kategorie ${data.name} wurde erfolgreich hinzugefügt`,
              'success'
            );
            this.visible = false;
            this.newGroupForm.reset();
            this.fileList = [];
          },
          error: (err) => {
            this.createMessage(err, 'error');
          },
        });
    } else {
      this.categoryService.addCategory(this.newGroupForm.value).subscribe({
        next: (data) => {
          this.getAllCategories();
          this.createMessage(
            this.langService.lang == 'en'
              ? `Category ${data.name} added succefully`
              : `Kategorie ${data.name} wurde erfolgreich hinzugefügt`,
            'success'
          );
          this.visible = false;
          this.newGroupForm.reset();
          this.fileList = [];
        },
        error: (err) => {
          this.createMessage(err, 'error');
        },
      });
    }
  }

  //search
  searchResult() {
    if (this.searchValue?.length) {
      this.categories = this.allCategories.filter((app: any) => {
        return app.name?.toLowerCase().includes(this.searchValue.toLowerCase());
      });
    } else {
      this.categories = this.allCategories;
    }
  }
  clearSearch() {
    this.searchValue = '';
    this.categories = this.allCategories;
  }

  //filteration according to status
  filterByStatus = (status: any) => {
    if (status == null) this.categories = this.allCategories;
    else if (status != 'all') {
      this.categories = this.allCategories.filter((app: ICategory) => {
        return app.isActive == status;
      });
    } else {
      this.categories = this.allCategories;
    }
  };

  highlightText(text: string, search: string): string {
    if (!search) {
      return text;
    }
    const re = new RegExp(search, 'gi');
    return text.replace(
      re,
      (match) => `<span class="highlight">${match}</span>`
    );
  }

  private getBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }
  handleChange(info: any): void {
    const file = info.file;

    if (file.status === 'done' || file.status === 'uploading') {
      this.getBase64(file.originFileObj).then((base64: string) => {
        this.newGroupForm.patchValue({
          imageBase64: {
            name: file.name,
            contentType: file.type,
            imageData: base64,
          },
        });
      });

      this.fileList = [{ ...file, showDownloadIcon: false }]; // Ensure only one file is kept in the list
    } else if (file.status === 'removed') {
      this.fileList = [];
      this.newGroupForm.patchValue({
        imageBase64: {
          name: '',
          contentType: '',
          imageData: '',
        },
      });
    }
  }

  handleDownload(file: any) {
    const link = document.createElement('a');
    link.href = file.url || file.thumbUrl;
    link.download = file.name;
    link.click();
  }

  //create message
  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }
}
