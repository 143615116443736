<section class="p-3">
  <div class="d-flex justify-content-between">
    <div>
      <button
        nz-button
        nzSize="large"
        nzType="default"
        class="addButton"
        (click)="createNewUser()"
      >
        {{ "users.addUser" | translate }}
        <span nz-icon nzType="user-add"></span>
      </button>
    </div>
    <div>
      <ng-template #suffixIconButton>
        <button nz-button nzType="primary" nzSearch>
          <span nz-icon nzType="search"></span>
        </button>
      </ng-template>

      <nz-input-group
        nzSearch
        [nzAddOnAfter]="suffixIconButton"
        [nzSuffix]="suffixIcon"
      >
        <input
          type="text"
          nz-input
          [placeholder]="'users.search' | translate"
          #searchValue
          [(ngModel)]="searchText"
          (input)="searchResult()"
        />
      </nz-input-group>
      <ng-template #suffixIcon>
        <i
          nz-icon
          nzType="close-circle"
          *ngIf="searchValue.value"
          (click)="clearSearch()"
        ></i>
      </ng-template>
      <!-- <ng-template #suffixclear>
        <span (click)="this.getAllUsers()">
          <span nz-icon nzType="close" nzTheme="outline"></span>
        </span>
      </ng-template> -->
    </div>
  </div>

  <!-- <div nz-row> -->
  <nz-table
    #basicTable
    [nzScroll]="{ x: 'auto' }"
    [nzData]="users"
    [nzFrontPagination]="false"
    [nzPageSize]="pageSize"
    [nzTotal]="totalUsers"
    [nzPageIndex]="currentPage"
    (nzPageIndexChange)="onPageChange($event)"
    (nzPageSizeChange)="onPageSizeChange($event)"
  >
    <thead>
      <tr>
        <th>{{ "users.userName" | translate }}</th>
        <th>{{ "users.firstName" | translate }}</th>
        <th>{{ "users.lastName" | translate }}</th>
        <th>{{ "users.email" | translate }}</th>
        <th
          [nzFilters]="statusFilters"
          [nzFilterMultiple]="false"
          (nzFilterChange)="filterByStatus($event)"
        >
          {{ "users.status" | translate }}
        </th>
        <th>{{ "users.actions" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of basicTable.data" style="cursor: pointer">
        <td class="text-51b2a8" [routerLink]="['/users/' + data?.id]">
          <span [innerHTML]="highlightText(data.username, searchText)"></span>
        </td>
        <td>
          <span >{{data.firstName}}</span>
        </td>
        <td>
          <span> {{data.lastName}} </span>
        </td>
        <td>
          <span>{{data.email}}</span>
        </td>
        <td>
          <span
            [class.bg-active]="data.enabled"
            [class.bg-notactive]="!data.enabled"
            >{{ data.enabled ? "Active" : "Inactive" }}</span
          >
        </td>
        <td>
          <a
            appClickPropagation
            nz-icon
            nzType="edit"
            nzTheme="outline"
            (click)="editUser(data)"
            class="text-primary"
          ></a>
          <nz-divider nzType="vertical"></nz-divider>
          <a
            appClickPropagation
            nz-icon
            nzType="delete"
            nzTheme="outline"
            nz-popconfirm
            [nzPopconfirmTitle]="'roles.confirmDelete' | translate"
            [nzOkText]="'roles.yes' | translate"
            [nzCancelText]="'roles.cancel' | translate"
            (nzOnConfirm)="confirm(data.id)"
            (nzOnCancel)="cancel()"
            class="text-danger"
          ></a>
        </td>
      </tr>
    </tbody>
  </nz-table>
</section>

<!-- </div> -->
<nz-drawer
  [nzBodyStyle]="{ overflow: 'auto' }"
  [nzMaskClosable]="false"
  [nzWidth]="500"
  [nzVisible]="visible"
  nzTitle="{{ drawerTitle }}"
  [nzFooter]="footerTpl"
  (nzOnClose)="close()"
>
  <form
    *nzDrawerContent
    nz-form
    [formGroup]="newUserForm"
    (ngSubmit)="createUser()"
  >
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="enabled">
        {{ "users.enabled" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
      >
        <nz-switch formControlName="enabled" name="enabled"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="otp">
        {{ "users.otp" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
      >
        <nz-switch formControlName="totp" name="totp"></nz-switch>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="email">
        {{ "users.email" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="{{ 'users.emailError' | translate }}"
      >
        <input nz-input formControlName="email" id="email" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="username">
        {{ "users.userName" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="{{ 'users.usernameError' | translate }}"
      >
        <input nz-input formControlName="username" id="username" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="!isUpdate">
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="password">
        {{ "users.password" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="{{ 'users.passwordError' | translate }}"
      >
        <input
          nz-input
          formControlName="password"
          id="password"
          type="password"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="firstName">
        {{ "users.firstName" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="{{ 'users.firstNameError' | translate }}"
      >
        <input nz-input formControlName="firstName" id="firstName" />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="lastName">
        {{ "users.lastName" | translate }}
      </nz-form-label>
      <nz-form-control
        [nzSm]="14"
        [nzXs]="24"
        nzErrorTip="{{ 'users.lastNameError' | translate }}"
      >
        <input nz-input formControlName="lastName" id="lastName" />
      </nz-form-control>
    </nz-form-item>
  </form>

  <ng-template #footerTpl>
    <div style="float: right">
      <button nz-button style="margin-right: 8px" (click)="close()">
        {{ "users.cancel" | translate }}
      </button>
      <button nz-button nzType="primary" (click)="createUser()">
        {{ "users.submit" | translate }}
      </button>
    </div>
  </ng-template>
</nz-drawer>

