import { Component, Input, OnInit } from '@angular/core';
import { KeycloakAdminService } from '../../../services/keycloak-admin.service';
import { NzTransferModule, TransferItem } from 'ng-zorro-antd/transfer';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { CommonModule } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { TranslateModule } from '@ngx-translate/core';
import { LangService } from '../../../services/lang.service';

@Component({
  selector: 'app-edit-user',
  standalone: true,
  imports: [
    NzTransferModule,
    NzTableModule,
    NzSwitchModule,
    CommonModule,
    FormsModule,
    NzInputModule,
    NzDrawerModule,
    ReactiveFormsModule,
    NzFormModule,
    NzButtonModule,
    TranslateModule,
  ],
  templateUrl: './edit-user.component.html',
  styleUrl: './edit-user.component.css',
})
export class EditUserComponent implements OnInit {
  @Input() id: any;
  userData: any;
  userRoles: any;
  realmRoles: any;
  disabled = false;
  visibleEdit: boolean = false;
  showSearch = false;
  combinedRoles: any;
  targetKeys: any;
  groups: any = [];
  allgroupData: any;
  userGroups: any;
  combinedGroups: any;
  targetKeysGroups: any;
  disapled: boolean = false;
  roleCurrentPage: number = 1;
  groupCurrentPage: number = 1;
  rolePageSize: number = 10;
  groupPageSize: number = 10;
  roleTotal: number = 0;
  groupTotal: number = 0;
  constructor(
    private kcAdminService: KeycloakAdminService,
    private message: NzMessageService,
    private fb: NonNullableFormBuilder,
    private langService: LangService
  ) {}
  async ngOnInit() {
    if (this.id) {
      this.getUserData(this.id);
      await this.getUserRoles(this.id);
      await this.getRealmRoles();
      this.handelTrasfareDirections();
      await this.loadTotalCount();

      await this.loadGroups();
      await this.getGroupsOfUser(this.id);
      this.handelTrasfareDirectionsForGroups();
    }
  }

  //implement change password form
  changePasswordForm: FormGroup = this.fb.group({
    password: ['', [Validators.required]],
    confirmationPassword: ['', [Validators.required]],
  });

  //handel trasnfare data and directions for rolles
  handelTrasfareDirections() {
    const userRoleIds = new Set(this.userRoles.map((role: any) => role.id));

    const filteredRealmRoles = this.realmRoles.filter(
      (role: any) => !userRoleIds.has(role.id)
    );

    // Combine userRoles and filteredRealmRoles
    this.combinedRoles = [
      ...this.userRoles.map((role: any) => ({
        id: role.id,
        name: role.name,
        direction: 'right',
      })),
      ...filteredRealmRoles.map((role: any) => ({
        id: role.id,
        name: role.name,
        direction: 'left',
      })),
    ];

    this.targetKeys = this.combinedRoles
      .filter((role: any) => role.direction === 'right')
      .map((role: any) => role.key);
  }

  //handel trasnfare data and directions for groups
  handelTrasfareDirectionsForGroups() {
    const userGroupId = new Set(this.userGroups.map((role: any) => role.id));

    const filteredRealmRoles = this.groups.filter(
      (role: any) => !userGroupId.has(role.id)
    );

    // Combine userRoles and filteredRealmRoles
    this.combinedGroups = [
      ...this.userGroups.map((group: any) => ({
        id: group.id,
        name: group.name,
        direction: 'right',
      })),
      ...filteredRealmRoles.map((group: any) => ({
        id: group.id,
        name: group.name,
        direction: 'left',
      })),
    ];

    this.targetKeysGroups = this.combinedGroups
      .filter((group: any) => group.direction === 'right')
      .map((group: any) => group.key);
  }

  //get user data
  getUserData(id: string) {
    this.kcAdminService.getUserInfo(id).then((data) => {
      this.userData = data;
    });
  }

  //get user role
  async getUserRoles(id: string) {
    await this.kcAdminService.getUserRole(id).then((data) => {
      this.userRoles = data?.realmMappings;
    });
  }

  //get all realm role
  async getRealmRoles() {
    await this.kcAdminService.getReleamRoles().then((data) => {
      this.realmRoles = data;
    });
  }

  //on transfare data
  onChange(event: any): void {
    if (event?.list?.length == 0) return;
    if (event.from == 'right') {
      //delete role
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          name: data.name,
          id: data.id,
        });
      });
      this.kcAdminService
        .deleteUserRoles(this.userData.id, newArray)
        .then((data) => {
          this.createMessage(
            this.langService.lang == 'en'
              ? 'The role has been successfully removed'
              : 'Die Rolle wurde erfolgreich entfernt',
            'success'
          );
        });
    } else {
      //add role
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          name: data.name,
          id: data.id,
        });
      });
      this.kcAdminService
        .addUserRoles(this.userData.id, newArray)
        .then((data) => {
          this.createMessage(
            (this.langService.lang = 'en'
              ? 'The role was added successfully'
              : 'Die Rolle wurde erfolgreich hinzugefügt'),
            'success'
          );
        });
    }
  }
  // get all groups
  async loadGroups() {
    const first = (this.groupCurrentPage - 1) * this.groupPageSize;

    await this.kcAdminService
      .getGroupsPagination(first, this.groupPageSize)
      .then((groups) => {
        this.allgroupData = groups;
        this.customizeGroups(groups);
      });
  }
  //customize all group to be in the same level
  customizeGroups(groups: any[]) {
    groups.forEach((group) => {
      this.groups.push(group);
      if (group.subGroups.length > 0) this.customizeGroups(group.subGroups);
    });
  }
  //get user's groups

  async getGroupsOfUser(userId: string) {
    await this.kcAdminService.getGroupsForUser(userId).then((groups) => {
      this.userGroups = groups;
    });
  }

  //on transfare data
  onChangeGroup(event: any): void {
    if (event?.list?.length == 0) return;
    this.disabled = true;
    if (event.from == 'right') {
      //delete role
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          id: data.id,
        });
      });
      this.kcAdminService
        .deleteUserFromGroup(this.userData.id, newArray[0]?.id)
        .then((data) => {
          this.createMessage(
            (this.langService.lang = 'en'
              ? 'The user was successfully removed from the group'
              : 'Der Benutzer wurde erfolgreich aus der Gruppe entfernt'),
            'success'
          );
          this.combinedGroups.forEach((data: any, index: number) => {
            this.combinedGroups[index].disapled = false;
            // this.combinedGroups[index].checked = true;
          });
        });
    } else {
      //add role
      const newArray: any[] = [];
      event?.list.forEach((data: any) => {
        newArray.push({
          id: data.id,
        });
      });
      this.kcAdminService
        .addUserToGroup(this.userData.id, newArray[0]?.id)
        .then((data) => {
          this.createMessage(
            (this.langService.lang = 'en'
              ? 'The user was successfully added to the group'
              : 'Der Benutzer wurde erfolgreich zur Gruppe hinzugefügt'),
            'success'
          );
          this.combinedGroups.forEach((data: any, index: number) => {
            this.combinedGroups[index].disapled = false;
            // this.combinedGroups[index].checked = true;
          });
        });
    }
  }
  //create message
  createMessage(message: string, type: string): void {
    this.message.create(type, `${message}`);
  }

  onItemSelect(data: any) {
    const newArray: any[] = [];
    data?.list.forEach((data: any) => {
      newArray.push({
        id: data.id,
      });
    });
    this.combinedGroups.forEach((data: any, index: number) => {
      if (data?.id != newArray[0]?.id) {
        this.combinedGroups[index].disapled = true;
        this.combinedGroups[index].checked = false;
      }
    });
  }
  close() {
    this.visibleEdit = false;
  }
  async changeUserPassword() {
    if (this.changePasswordForm.value.password.length >= 8) {
      await this.kcAdminService
        .updateUserPassword(this.id, this.changePasswordForm.value.password)
        .then((data) => {
          this.createMessage(
            (this.langService.lang = 'en'
              ? 'The password was changed successfully'
              : 'Das Passwort wurde erfolgreich geändert'),
            'success'
          );
          this.close();
          this.changePasswordForm.reset();
        })
        .catch((err) => {
          // console.log(err);
          this.close();
        });
    }
  }

  handleRoleChange(event: any): void {
    console.log('Transfer change event:', event);
  }
  handleGroupChange(event: any): void {
    console.log('Transfer change event:', event);
  }

  onPageRoleChange(page: number): void {
    this.roleCurrentPage = page;
    // this.loadData(page, this.pageSize);
  }

  onPageGroupChange(page: number): void {
    this.groupCurrentPage = page;
    this.loadGroups();
  }

  async loadTotalCount() {
    await this.kcAdminService.getGroupsTotalCount().then(
      (data: any) => {
        this.groupTotal = data; // Update this line based on your data structure
      },
      (error) => {
        console.error('Error fetching users', error);
      }
    );
  }

  // async loadRolesTotalCount() {
  //   await this.kcAdminService.getRolesTotalCount().then(
  //     (data: any) => {
  //       this.groupTotal = data; // Update this line based on your data structure
  //     },
  //     (error) => {
  //       console.error('Error fetching users', error);
  //     }
  //   );
  // }
}
